<template>
  <div class="change-box text-center">
    <div v-if="isNormalLayout" class="title p-1 text-white">{{ props.title }}</div>
    <div class="flex items-center" :class="{ 'p-3' : isNormalLayout }">
      <div v-if="isLandscapeLayout" class="title p-1 text-white w-3/12">{{ props.title }}</div>
      <div :class="`w-${widthRatio}/12`">{{ typeof props.before === 'number' ? props.before.toLocaleString() : props.before }}</div>
      <CommonArrow />
      <div class="text-red-500" :class="`w-${widthRatio}/12`">{{ typeof props.after === 'number' ? props.after.toLocaleString() : props.after }}</div>
      <div v-if="upAndDown && typeof before === 'number' && typeof after === 'number'" class="flex justify-center w-1/12" :class="{ 'mr-2' : isLandscapeLayout }">
        <img v-if="before < after" class="up-down up-down-image" src="~/assets/images/common/common_arrow_up.png">
        <img v-else class="up-down up-down-image" src="~/assets/images/common/common_arrow_down.png">
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  title: string,
  before: number | string,
  after: number | string,
  layout?: 'normal' | 'landscape',
  upAndDown?: boolean,
}>(), {
  layout: 'normal',
  upAndDown: false,
});
const isNormalLayout = computed(() => {
  return props.layout === 'normal';
});
const isLandscapeLayout = computed(() => {
  return props.layout === 'landscape';
});
const widthRatio = computed(() => {
  if (props.layout === 'landscape') {
    return '3';
  } else if (props.upAndDown) {
    return '4';
  } else {
    return '5';
  }
});
</script>

<style lang="scss" scoped>
.change-box {
  border-image: url("~/assets/images/common/common_status_bg_2.png")  15 15 fill/ 5px 5px stretch;
  overflow: hidden;

  .title {
    border-image: url("~/assets/images/common/common_status_bg_9.png") 15 15 fill / 5px 5px stretch;
  }

  $up-down-size: 20px;

  .up-down {
    animation: up-point-bounce 1s infinite;
    width: 100%;
  }

  @keyframes up-point-bounce {
    0%,
    40%,
    80%,
    100% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }

    20%,
    60% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
  }

  .up-down-image {
    width: $up-down-size;
    height: $up-down-size;
  }
}
</style>
